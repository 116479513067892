import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setMember, resetMembers, setReloadFetch, setEditingFair, setFairInDepth, setAddingNewFair, createNewFairFunc } from '../states/reportState';
import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { DateRange } from 'react-date-range';

const ReportsFairForm = ({ user, team }) => {
    const partecipants_ = useSelector((state) => state.report.partecipants);
    const isPostingFair = useSelector((state) => state.report.isPostingFair);
    const dispatch = useDispatch();

    const [eventName, setEventName] = useState("");
    const [year, setYear] = useState("");
    /* const [date, setDate] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`); */
    const [stand, setStand] = useState(0);
    const [positionRate, setPositionRate] = useState(0);
    const [prevEditionRate, setPrevEditionRate] = useState(0);
    const [materialDistribution, setMaterialDistribution] = useState("");
    const [problems, setProblems] = useState("");
    const [comments, setComments] = useState("");
    const [generalRate, setGeneralRate] = useState(0);
    const [notes, setNotes] = useState("");
    const [partecipants, setPartecipants] = useState("");



    const [message, setMessage] = useState("");
    const [isEdited, setIsEdited] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(true);

    //star rating
    const [isSelected, setIsSelected] = useState(false);


    const createNewFair = async () => {
        try {
            const payload = {
                eventName: eventName,
                year: year,
                date: `${state[0].startDate}%${state[0].endDate}`,
                stand: stand,
                positionRate: positionRate,
                prevEditionRate: prevEditionRate,
                materialDistribution: materialDistribution,
                problems: problems,
                comments: comments,
                generalRate: generalRate,
                notes: notes,
                partecipants: partecipants,
                createdBy: user
            };
            if (!eventName || !year) { setIsFormFilled(false); return };
            setIsFormFilled(true);//just to trigger form input filling alert
            dispatch(createNewFairFunc(payload));
            setIsEdited(true);//to avoid first open reload and reset of the form
            return

        } catch (error) {
            console.error(error);
        }

    };

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        if (!isPostingFair && isEdited) {
            dispatch(setEditingFair(false));
            dispatch(setFairInDepth(false));
            dispatch(setAddingNewFair(false));
            dispatch(setReloadFetch());
            setIsEdited(false);
        }
    }, [isPostingFair])

    useEffect(() => {
        if (partecipants_.length === 0) { setPartecipants("") } else { setPartecipants(partecipants_.toString()) }
    }, [partecipants_]);



    return (
        <div className='pt-2 px-2' >

            

            <h2 className='text-primary fw-light'>Add a New Event</h2>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Event Name
                </InputGroup.Text>
                <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setEventName(e.target.value) }}
                    value={eventName}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Year
                </InputGroup.Text>
                <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setYear(e.target.value) }}
                    value={year}
                />
            </InputGroup>
           
            <DateRange className="mb-4 mt-1"
                editableDateInputs={true}
                onChange={(item) => {setState([item.selection])}}
                moveRangeOnFirstSelection={false}
                ranges={state}
            />

            <div className="mb-3">
                <h6 className='text-light m-0 mb-1'><i className="bi bi-shop"> Stand</i></h6>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Stand"
                    onClick={(e) => { setStand(e.target.checked ? 1 : 0) }}
                />
            </div>

            <div className='mb-3'>
                <h6 className='text-light m-0'><i className="bi bi-award-fill"> General Rate</i></h6>
                <div>
                    {
                        [...Array(5)].map((el, index) => {
                            return <i
                                className={`bi bi-star${generalRate > index ? "-fill" : ""} postRatingStars`}
                                onMouseEnter={() => { if (!isSelected) { setGeneralRate(index + 1) } }}
                                onMouseLeave={() => { if (!isSelected) { !isSelected ? setGeneralRate(0) : setGeneralRate(index + 1) } }}
                                onClick={() => { setGeneralRate(index + 1); setIsSelected(true) }}></i>

                        })
                    }
                </div>
            </div>

            <div className='mb-3'>
                <h6 className='text-light m-0'><i className="bi bi-geo-alt-fill"> Position Rate</i></h6>
                <div>
                    {
                        [...Array(5)].map((el, index) => {
                            return <i
                                className={`bi bi-star${positionRate > index ? "-fill" : ""} postRatingStars`}
                                onMouseEnter={() => { if (!isSelected) { setPositionRate(index + 1) } }}
                                onMouseLeave={() => { if (!isSelected) { !isSelected ? setPositionRate(0) : setPositionRate(index + 1) } }}
                                onClick={() => { setPositionRate(index + 1); setIsSelected(true) }}></i>

                        })
                    }
                </div>
            </div>

            <div className='mb-3'>
                <h6 className='text-light m-0'><i className="bi bi-graph-up-arrow"> Prev. Edition Rate</i></h6>
                <div>
                    {
                        [...Array(5)].map((el, index) => {
                            return <i
                                className={`bi bi-star${prevEditionRate > index ? "-fill" : ""} postRatingStars`}
                                onMouseEnter={() => { if (!isSelected) { setPrevEditionRate(index + 1) } }}
                                onMouseLeave={() => { if (!isSelected) { !isSelected ? setPrevEditionRate(0) : setPrevEditionRate(index + 1) } }}
                                onClick={() => { setPrevEditionRate(index + 1); setIsSelected(true) }}></i>

                        })
                    }
                </div>
            </div>

            <div>
                <h6 className='text-light m-0 mb-2'><i className="bi bi-box-seam-fill"> Material Distribution</i></h6>
                <InputGroup className="mb-3">
                    <Form.Control
                        as="textarea"
                        rows="3"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={(e) => { setMaterialDistribution(e.target.value) }}
                        value={materialDistribution}
                    />
                </InputGroup>
            </div>
            <div>
                <h6 className='text-light m-0 mb-2'><i className="bi bi-emoji-angry-fill"> Problems</i></h6>
                <InputGroup className="mb-3">
                    <Form.Control
                        as="textarea"
                        rows="4"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={(e) => { setProblems(e.target.value) }}
                        value={problems}
                    />
                </InputGroup>
            </div>
            <div>
                <h6 className='text-light m-0 mb-2'><i className="bi bi-chat-dots-fill"> Comments</i></h6>
                <InputGroup className="mb-3">
                    <Form.Control
                        as="textarea"
                        rows="3"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={(e) => { setComments(e.target.value) }}
                        value={comments}
                    />
                </InputGroup>
            </div>


            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Notes
                </InputGroup.Text>
                <Form.Control
                    as="textarea"
                    rows="9"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setNotes(e.target.value) }}
                    value={notes}
                />
            </InputGroup>

            <h6 className='text-light mb-0'><i className="bi bi-people-fill"> partecipants</i></h6>
            <div className='text-light mb-2 d-flex flex-wrap'>
                {partecipants && partecipants.split(",").map((el) => { return <div className='bg-info m-1 p-1 px-3 rounded-5'>{" " + team[+el - 1].name + " " + team[+el - 1].surname}</div> })}
            </div>
            <div className='d-flex align-items-center justify-content-between'>
                <Dropdown className='mt-2'>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Choose a partecipants
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {team && team.map((el) => {
                            return <Dropdown.Item onClick={() => { dispatch(setMember(el.id)) }}>{el.name + " " + el.surname}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <i className="bi bi-trash-fill text-danger" onClick={() => { dispatch(resetMembers()); setPartecipants("") }} style={{ cursor: "pointer" }}> delete</i>
            </div>


            <Button className='mt-5 w-100 text-light' variant="primary" size="sm" onClick={() => { createNewFair() }}>
                {
                    isPostingFair ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> :
                        <i className='bi bi-plus-lg'></i>
                } Add
            </Button>
            <div className='mt-2'>{!isFormFilled ? <Alert variant="danger">Fill at least Event Name and Year!</Alert> : ""}</div>
            <p className='text-light mt-2'>{message}</p>
        </div>
    )
}

export default ReportsFairForm