import React, { useEffect } from 'react';
import { nanoid } from 'nanoid';
import digitalLogo from '../assets/logo/digitalLogoSM.png';
import paperLogo from '../assets/logo/paperLogoSM.png';
import eventsLogo from '../assets/logo/eventsLogoSM.png';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import servicesJson from '../assets/json/allServices.json'

const ServiceShortCard = () => {
    const category = useSelector((state) => state.navigation.serviceCategory);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(category);
    }, [category])

    return (
        <div className='pt-1 pb-5 d-flex myOurServices justify-content-center flex-wrap myScrollY' style={{ maxHeight: "calc(100vh - 106px)" }}>
            {
                servicesJson && servicesJson.map((el) => {
                    if (category === "All Services") {
                        return (
                            <Link className='noDecoration' to={`/ourservices/${el.route}`}>
                                <div className='allProductsCard rounded-5 px-4 py-2 pb-4 mb-2 mx-2 myCursor'
                                    style={{ border: `2px solid ${el.category === "digital" ? "#17365d" : el.category === "paper" ? "#14a7ad" : "#e82283"}` }}
                                    key={(nanoid())}
                                >
                                    <div className='text-center text-light'>
                                        <img className='my-3 myMaxWidth150' src={require(`../assets/images/_allServices/${el.id}.png`)} alt="" />
                                        <div className='d-flex justify-content-center align-items-center my-3'>
                                            {
                                                el.category === "digital" ?
                                                    <img src={digitalLogo} alt="" style={{ height: "20px" }} /> :
                                                    el.category === "events" ?
                                                        <img src={eventsLogo} alt="" style={{ height: "20px" }} /> :
                                                        <img src={paperLogo} alt="" style={{ height: "20px" }} />
                                            }
                                            <h5 className='mb-0 ms-2'>{el.name}</h5>
                                        </div>
                                        <hr className='my-1' />
                                        <p className='mb-0 fw-light myTruncate2'><i>{el.description}</i></p>
                                        <hr className='my-2' />
                                    </div>
                                    <div className='demScore d-flex justify-content-around'>
                                        <div className='efficacyScore'>
                                            <i className='mb-0'>efficacy</i>
                                            <div>
                                                {
                                                    [...Array(5)].map((item, index) => {
                                                        if (el.efficacy_score >= index + 1) {
                                                            return <i className="bi bi-circle-fill me-1" key={nanoid()}></i>
                                                        } else if (el.efficacy_score > index && el.efficacy_score < index + 2) {
                                                            return <i className="bi bi-circle-half me-1" key={nanoid()}></i>
                                                        } else {
                                                            return <i className="bi bi-circle me-1" key={nanoid()}></i>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className='priceScore'>
                                            <i className='mb-0'>price</i>
                                            <div>
                                                {
                                                    [...Array(5)].map((item, index) => {
                                                        if (el.price_score >= index + 1) {
                                                            return <i className="bi bi-circle-fill me-1" key={nanoid()}></i>
                                                        } else if (el.price_score > index && el.price_score < index + 2) {
                                                            return <i className="bi bi-circle-half me-1" key={nanoid()}></i>
                                                        } else {
                                                            return <i className="bi bi-circle me-1" key={nanoid()}></i>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    } else if (category.toLowerCase() === el.category) {
                        return (
                            <Link className='noDecoration' to={`/ourservices/${el.route}`}>
                                <div className='allProductsCard rounded-5 px-4 py-2 pb-4 mb-2 mx-2 myCursor'
                                    style={{ border: `2px solid ${el.category === "digital" ? "#17365d" : el.category === "paper" ? "#14a7ad" : "#e82283"}` }}
                                    key={(nanoid())}
                                >
                                    <div className='text-center text-light'>
                                        <img className='my-3 myMaxWidth150' src={require(`../assets/images/_allServices/${el.id}.png`)} alt="" />
                                        <div className='d-flex justify-content-center align-items-center my-3'>
                                            {
                                                el.category === "digital" ?
                                                    <img src={digitalLogo} alt="" style={{ height: "20px" }} /> :
                                                    el.category === "events" ?
                                                        <img src={eventsLogo} alt="" style={{ height: "20px" }} /> :
                                                        <img src={paperLogo} alt="" style={{ height: "20px" }} />
                                            }
                                            <h5 className='mb-0 ms-2'>{el.name}</h5>
                                        </div>
                                        <hr className='my-1' />
                                        <p className='mb-0 fw-light myTruncate2'><i>{el.description}</i></p>
                                        <hr className='my-2' />
                                    </div>
                                    <div className='demScore d-flex justify-content-around'>
                                        <div className='efficacyScore'>
                                            <i className='mb-0'>efficacy</i>
                                            <div>
                                                {
                                                    [...Array(5)].map((item, index) => {
                                                        if (el.efficacy_score >= index + 1) {
                                                            return <i className="bi bi-circle-fill me-1" key={nanoid()}></i>
                                                        } else if (el.efficacy_score > index && el.efficacy_score < index + 2) {
                                                            return <i className="bi bi-circle-half me-1" key={nanoid()}></i>
                                                        } else {
                                                            return <i className="bi bi-circle me-1" key={nanoid()}></i>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className='priceScore'>
                                            <i className='mb-0'>price</i>
                                            <div>
                                                {
                                                    [...Array(5)].map((item, index) => {
                                                        if (el.price_score >= index + 1) {
                                                            return <i className="bi bi-circle-fill me-1" key={nanoid()}></i>
                                                        } else if (el.price_score > index && el.price_score < index + 2) {
                                                            return <i className="bi bi-circle-half me-1" key={nanoid()}></i>
                                                        } else {
                                                            return <i className="bi bi-circle me-1" key={nanoid()}></i>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    }

                })
            }
        </div>
    )
}

export default ServiceShortCard