import React from 'react';
import Myvideo from '../assets/video/vidHome.mp4';
import Home_AboutUs from './Home_AboutUs';
import Home_OurServices from './Home_OurServices';
import diagBlack from '../assets/graphics/diagonal_black.png';
import infodent from '../assets/images/paperMagazine/infodent.png';
import infomedix from '../assets/images/paperMagazine/infomedix.png';
import tabloid from '../assets/images/paperMagazine/tabloid.png';



const _Home = () => {

    return (
        <div style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>
            <div>
                <div className='myBgDark' style={{ height: "calc(100vh - 59px)", overflow: "hidden", zIndex: "7" }}>
                    <video src={Myvideo} autoPlay muted style={{ width: "100vw", height: "100%", objectFit: "cover" }} />
                </div>
            </div>

            <div className='myBgTransparent50' style={{ backgroundImage: `url(${diagBlack})`, height: "100px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "100% 100%" }}></div>

            {/* part 0 */}
            <div className='text-light pb-5 myBgTransparent50'>
                <div className='spacerLg'>{/* adaptive spacer */}</div>
                <h1 className='fw-bold display-4 m-0 mb-4 px-3 pt-5 text-center'>Visit our Magazines Websites</h1>
                <div className='d-flex flex-wrap justify-content-center pt-4 gap-2'>
                    <a className='noDecoration text-center myTextFucsia' target='_blank' href="https://www.infomedixinternational.com/">
                        <img className="myMaxWidth90" src={infomedix} alt="" />
                        <p>Medical <br /> International</p>
                    </a>
                    <a className='noDecoration text-center myTextFucsia' target='_blank' href="https://www.infodentinternational.com/">
                        <img className="myMaxWidth90" src={infodent} alt="" />
                        <p>Dental <br /> International</p>
                    </a>
                    <a className='noDecoration text-center myTextFucsia' target='_blank' href="https://www.infomedixodontoiatria.it/">
                        <img className="myMaxWidth90" src={tabloid} alt="" />
                        <p>Dental <br /> Italy</p>
                    </a>
                </div>
                <div className='spacerLg'>{/* adaptive spacer */}</div>
            </div>

            <Home_AboutUs />
            <Home_OurServices />
        </div>
    )
}

export default _Home