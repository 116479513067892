import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import myLogo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const NavigationBar = () => {

  const [isHamMenuOpen, setIsHamMenuOpen] = useState(false);

  return (
    <>
      <div className='myBgDark position-fixed w-100' expand="lg" style={{ zIndex: "10" }}>
        <Container >
          <div className='d-flex align-items-center justify-content-between py-1' >
            <div className='text-light myCursor'><Link to={"/"}><img className='m-2' src={myLogo} onClick={() => { setIsHamMenuOpen(false) }} alt="logo" style={{ height: "35px" }} /></Link></div>
            <div className="hamButton"><i className='bi bi-grid-fill text-light m-2' onClick={() => setIsHamMenuOpen(!isHamMenuOpen)}></i></div>
          </div>
        </Container>
      </div>
      <div className="bg-transparent" style={{ height: "59px" }}>
        {/* This is my nabar spacer. It represents the navbar height. */}
      </div>
      {
        isHamMenuOpen ?

          <div className='myBgTransparent90 d-flex justify-content-end hamMenu position-absolute w-100 mt-5 pt-2 top-0 ' style={{overflowY: "scroll", height: "calc(100vh - 48px)"}} >
            <div className='myScroll w-100' style={{minHeight: "calc(100vh - 59px)"}}>


              <div className='pb-5'>
                <div >
                  <ul className='text-light pt-4 pe-4 text-end mb-0'>
                    <Link to={"/aboutus"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>About Us</span> </li></Link>
                    <Link to={"/ourservices"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>Our Services</span> </li></Link>
                    <Link to={"/ourdatabase"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>Our Database</span> </li></Link>
                    <Link to={"/ourstrategy"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>Our Strategy</span> </li></Link>
                    <Link to={"/contactus"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>Contacts</span> </li></Link>
                    <Link to={"/login"}><li> <span onClick={() => { setIsHamMenuOpen(false) }}>Login</span> </li></Link>
                  </ul>
                </div>
                <hr className='text-light mx-4 my-4' />
                <div>
                  <h5 className='text-center text-light fw-light'> <i>Our 360° Marketing Strategy</i> </h5>
                  <div className='d-flex justify-content-center'>
                    <div className='serviceBlock d-flex flex-wrap gap-2 justify-content-center pt-3 text-light px-1' style={{ maxWidth: "700px" }}>
                      <Link to={"/ourservices/papermagazines"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Paper Magazines</div></Link>
                      <Link to={"/ourservices/coexhibiton"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Trade Shows Co-exhibition</div></Link>
                      <Link to={"/ourservices/dem"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Direct Email Marketing (DEM)</div></Link>
                      <Link to={"/ourservices/leaflet"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Leaflet Distribution</div></Link>
                      <Link to={"/ourservices/socialmedia"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Social Media</div></Link>
                      <Link to={"/ourservices/animations"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>3D Animations</div></Link>
                      <Link to={"/ourservices/eventsorganizzation"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Events Organizzation</div></Link>
                      <Link to={"/ourservices/videointerview"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Video Interviews</div></Link>
                      <Link to={"/ourservices/courseswebinar"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Courses & Webinar</div></Link>
                      <Link to={"/ourservices/professionalvideo"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Professional Product Video</div></Link>
                      <Link to={"/ourservices/scientificbooks"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Scientific Books</div></Link>
                      <Link to={"/ourservices/pressoffice"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Press Office</div></Link>
                      <Link to={"/ourservices/showcase"}><div className='px-3 py-2 border rounded-5' onClick={() => { setIsHamMenuOpen(false) }}>Real Showcase</div></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> :
          null
      }
    </>
  )
}

export default NavigationBar