import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import videoMaker from '../../assets/images/productVideo/video-making.jpg';
import mrkCampaign from '../../assets/images/productVideo/marketing.png';
import camera from '../../assets/images/productVideo/camera.png';
import social from '../../assets/images/productVideo/social-media.png';
import responsive from '../../assets/images/productVideo/responsive-design.png';
import editingTeam from '../../assets/images/videoInterview/teamEditing.jpg';
import ita from '../../assets/images/productVideo/italy.png';
import eng from '../../assets/images/productVideo/united-kingdom.png';
import youtube from '../../assets/images/videoInterview/youtube.png';

const ProfessionalVideoPage = () => {
  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${videoMaker})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>Professional Product Video</h1>
            <h2 className='font-nycd text-info my-5'>LET YOUR PRODUCT BE THE STAR</h2>
            <h3 className='fw-light'>In the digital era, professionally shot and visually dynamic videos are the best brand ambassadors you could possibly hope for. </h3>
          </div>
        </div>

        <div className='bg-dark'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex flex-wrap justify-content-center gap-1 mb-5'>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/TgmRmSUg8fo?si=xyNRTTqPUrU8yQ8J" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/NfJJ9x-mVZA?si=OarSfvQaTXZx98_1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/YzmBqGK4BTc?si=CRCReHJKYzYgCCSc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/WD3E2TSDFOI?si=av1W0kyDBAb2Ej8_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/enTywv8afa0?si=BbCbrF-CIfiphPT2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/GgCG6da51m8?si=lSVJiDHrlJwim3R1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className='my-5 py-5 d-flex justify-content-center '>
            <a className='noDecoration d-flex justify-content-center align-items-center gap-3' href="https://www.youtube.com/@InfodentSrl/videos" target='_blank'>
              <img className='myMaxWidth60' src={youtube} alt="" />
              <h2 className='m-0'>Watch all our Product Video</h2>
            </a>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <h2 className='text-secondary myMaxWidth700'>Compared to other digital media, videos have been proven to be the <b>most effective way to promote products and services</b>. Straight-forward and easily receivable, videos grab the viewer’s attention and efficiently get your message across, creating a lasting connection that ensures that your <b>products are remembered and recognized</b>.</h2>
            <img className='myMaxWidth200' src={mrkCampaign} alt="" />
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth200' src={camera} alt="" />
            <h2 className='myMaxWidth700'>Professional product videos can be shot during the main <b>trade fairs</b>, at <b>company events</b>, or our team can arrange a day of shootings at your <b>headquarters</b>. Product videos are recorded using top quality equipment that guarantees the greatest results, and post-production is taken care of right afterwards.</h2>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 pt-5 d-flex justify-content-center gap-5'>
            <img className='myMaxWidth80' src={social} alt="img" />
            <img className='myMaxWidth80' src={responsive} alt="img" />
          </div>
          <h2 className='text-secondary text-center myMaxWidth1200'>According to your preferences, the final videos can be uploaded onto <b>YouTube</b> or shared on <b>social media platforms</b>: all videos will be shot in the corresponding and most <b>fitting formats</b> to ensure great results and usability. </h2>
          <h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Make your product unforgettable!</h1>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${editingTeam})` }}>
          <div className='p-5 myBgTransparent60 text-light d-flex flex-column align-items-center'>
            <h2 className='fw-light my-5 myMaxWidth1200'>Everything else, from editing to the final touches of post-production, will be taken care by our team to meet you with the finished product in the shortest possible time.
              Enhance your chances to be seen and heard: choose videos to get your story out there.
            </h2>
            <div className='py-5 pt-5 d-flex justify-content-center gap-4'>
              <img className='myMaxWidth50' src={ita} alt="img" />
              <img className='myMaxWidth50' src={eng} alt="img" />
            </div>
            <h2 className='fw-light mb-5 myMaxWidth1200'>Available in english and italian language.</h2>
          </div>
        </div>

      </div>
    </MainLayout>
  )
}

export default ProfessionalVideoPage