import React from 'react';
import MainLayout from '../layouts/MainLayout';
import _ContactUs from '../components/_ContactUs';

const ContactUsPage = () => {
  return (
    <>
        <MainLayout>
          <_ContactUs/>
        </MainLayout>
    </>
  )
}

export default ContactUsPage