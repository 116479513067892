import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    error: "",
    allUsersData: []
};

const getAllUserFunc = createAsyncThunk(
    "getAllUser/fetchLogin",
    async () => {

        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/allusers`, {
                method: "GET",
                headers: {
                    "Authorization": localStorage.getItem("tkn")
                }
            });
            return await response.json();

        } catch (error) {
            console.error(error);
        }
    }
)

const createUserFunc = createAsyncThunk(
    "createUser/fetchLogin",
    async (input) => {

        try {
            const { email, pssw } = input;

            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/createuser`, {
                method: "POST",
                headers: {
                    "Authorization": localStorage.getItem("tkn"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: email.replace(/\s+/g, ' ').trim(),
                    pssw: pssw.replace(/\s+/g, ' ').trim()
                })
            });

            const res = await response.json();
            return res

        } catch (error) {
            console.error(error);
        }
    }
)

const deleteUserFunc = createAsyncThunk(
    "deleteUser/fetchLogin",
    async (input) => {
        console.log(localStorage.getItem("tkn"));
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/deleteuser?id=${input}`, {
                method: "DELETE",
                headers: {
                    "Authorization": localStorage.getItem("tkn")
                }
            });
            const res = await response.json();
            return res

        } catch (error) {
            console.error(error);
        }
    }
)

const createUserSlice = createSlice({
    name: "createUserSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //get all user
        builder.addCase(getAllUserFunc.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(getAllUserFunc.fulfilled, (state, action) => {
            state.isLoading = false;
            state.allUsersData = action.payload
        });
        builder.addCase(getAllUserFunc.rejected, (state, action) => {
            state.isLoading = false;
            state.error = "server error extrareducers"
        });

        //create user
        builder.addCase(createUserFunc.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(createUserFunc.fulfilled, (state, action) => {
            state.isLoading = false
        });
        builder.addCase(createUserFunc.rejected, (state, action) => {
            state.isLoading = false;
            state.error = "server error extrareducers"
        });

        //delete user
        builder.addCase(deleteUserFunc.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(deleteUserFunc.fulfilled, (state, action) => {
            state.isLoading = false
        });
        builder.addCase(deleteUserFunc.rejected, (state, action) => {
            state.isLoading = false;
            state.error = "server error extrareducers"
        });
    }
})



export default createUserSlice.reducer;
export { createUserFunc, deleteUserFunc, getAllUserFunc }