import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import showcaseBg from '../../assets/images/showcase/showcase.jpg';
import rocket from '../../assets/images/showcase/rocket.png';
import expo from '../../assets/images/eventsOrganizzation/poster.png';
import bgTrade from '../../assets/images/coExhibition/bgTradeShow.jpg';
import showcase from '../../assets/images/coExhibition/showcase.png';
import staff from '../../assets/images/coursesWebinars/team.png';


const ShowcasePage = () => {
  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${bgTrade})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>Showcase</h1>
            <h2 className='font-nycd text-info my-5'>LET THE INDUSTRY MEET YOUR PRODUCTS</h2>
            <h2 className='fw-light'>A fantastic opportunity to boost your export by reaching a new global audience and gaining contacts in new areas.</h2>
          </div>
        </div>

        <div className=' myBgImgCover' style={{ backgroundImage: `url(${showcaseBg})`, height: "400px" }}></div>

        <div className='p-5 d-flex flex-column align-items-center text-secondary'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 mb-4 d-flex justify-content-center align-items-center'>
            <img className='myMaxWidth150' src={expo} alt="img" />
          </div>
          <h2 className='text-center myMaxWidth1200'>Are you unable to attend a trade fair or exhibition you wish you could be part of? Your products can still be there! With our real showcase services, <b>your products can travel the world</b> even when you can’t follow them. </h2>
          <h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>Organizing and delivering great events is no longer an overwhelming process. </h1>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 mb-4 d-flex justify-content-center align-items-center'>
            <img className='myMaxWidth200' src={showcase} alt="img" />
          </div>
          <h2 className=' text-center myMaxWidth1200'>We are present at all the <b>major events</b> in the dental and medical industry and are always ready to take your brand along. This is how we do it: <b>we collect your products and safely carry them wherever you would like them to be showcased</b>. Our booths are guaranteed central and accessible locations at all major exhibitions, so your brand will be on display right at the heart of the action. </h2>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>


        <div className='p-5 d-flex flex-column align-items-center text-secondary'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='pb-5 pt-3 d-flex justify-content-center align-items-center gap-4'>
            <img className='myMaxWidth200' src={staff} alt="img" />
          </div>
          <h2 className=' text-center myMaxWidth1200'>All throughout the event, our staff will promote your products and brand and collect <b>leads</b>, <b>business cards</b> and <b>contact information</b> to be sent to you right afterwards.</h2>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth200' src={rocket} alt="" />
            <div>
              <h2 className='myMaxWidth700'> A fantastic opportunity to boost your export by reaching a <b>new global audience</b> and gaining <b>contacts in new areas</b> – or attending multiple events while optimizing the financial commitments they require. </h2>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

      </div>
    </MainLayout>
  )
}

export default ShowcasePage