import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';

//cookies
import CookieConsent from "react-cookie-consent";

//Redux imports
import { Provider } from 'react-redux';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import navigationSlice from './states/navigationState';
import reportSlice from './states/reportState';
import loginSlice from './states/loginState';
import generalDataSlice from './states/generalDataState';
import createUserSlice from './states/createUserState';
//font imports
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
//react-date-range - https://www.npmjs.com/package/react-date-range - https://hypeserver.github.io/react-date-range/
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const rootReducer = combineReducers({
  navigation: navigationSlice,
  generalData: generalDataSlice,
  report: reportSlice,
  login: loginSlice,
  createUser: createUserSlice
})
const store = configureStore({
  reducer: rootReducer
})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="yAwesomeCookieName2"
        style={{ background: "#17365d", boxShadow: "0px 0px 40px rgba(0,0,0,0.3)", width: "100%" }}
        buttonStyle={{ color: "#fff", background: "#e82283", borderRadius: "2px", fontSize: "20px" }}
        expires={30}
        buttonWrapperClasses="cookieBtn"
      >
        <p className='text-light fw-light px-2 pt-4 w-100'>This website or its third party tools use cookies, which are necessary to its functioning and required to achieve the purposes illustrated in the cookie policy. If you want to know more or withdraw your consent to all or some of the cookies, please refer to the cookie policy. By closing this banner, scrolling this page, clicking a link or continuing to browse otherwise, you agree to the use of cookies.
        </p>
        &nbsp;
        <div className='text-center'>
          <a className='text-info fw-bold noDecoration' href="/cookies" >Cookies and Privacy Policy</a>
        </div>
      </CookieConsent>
    </Provider>
  </React.StrictMode>
);

