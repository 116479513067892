import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import _Reports from './_Reports';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLogged, loginFunc } from '../states/loginState';
import { setSuperUser } from '../states/loginState';


const _Login = () => {

    const [email, setEmail] = useState("");
    const [pssw, setPssw] = useState("");
    const [message, setMessage] = useState("");

    const isLogged = useSelector((state) => state.login.isLogged);
    const isLoginLoading = useSelector((state) => state.login.isLoading);
    const dispatch = useDispatch();

    const loginSubmit = async () => {

        if (!email || !pssw) {

            setMessage("Fill all input fields!")
            return

        } else {

            dispatch(loginFunc({ email: email, pssw: pssw }))
                .then((res) => {
                    if (res.payload.statusCode === 200) {
                        if(res.meta.arg.email === "luca.pipitone@infodent.com"){
                            dispatch(setSuperUser(true))
                        };
                        setEmail("");
                        setPssw("");
                        localStorage.setItem("tkn", res.payload.token);
                        res.payload.token ? dispatch(setIsLogged(true)) : dispatch(setIsLogged(false));
                    }
                    setMessage(res.payload.message)
                })
                .catch((err) => {
                    setMessage(err)
                })

        }


    };

    useEffect(() => {
        const tkn = localStorage.getItem("tkn");
        tkn ? dispatch(setIsLogged(true)) : dispatch(setIsLogged(false));
    }, []);

    return (

        !isLogged ?
            <div className='d-flex align-items-center justify-content-center' style={{ height: "80vh" }}>
                <div className='bg-light p-4 rounded-5 d-flex flex-column justify-content-center align-items-center' style={{ width: "90vw", maxWidth: "600px" }}>
                    <h2 className='mb-4 text-center text-secondary'>login</h2>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-default">
                            email
                        </InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            onChange={(e) => { setEmail(e.target.value) }}
                            value={email}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-default">
                            password
                        </InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            type='password'
                            onChange={(e) => { setPssw(e.target.value) }}
                            value={pssw}
                        />
                    </InputGroup>
                    <Button className='mt-3 w-50' variant="primary" size="sm" onClick={() => { loginSubmit() }}>{isLoginLoading ? <Spinner animation="border" size="sm" /> : "login"}</Button>
                    <p className='mt-2'>{message}</p>
                </div>
            </div> :

            <div>
                <_Reports />
            </div>
    )
}

export default _Login