import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    isLoadingTeam: false,
    allTeam: [],
    singleService: [{}],
    isLoadingDem: false,
    allDem: []
};

const getAllTeamFunc = createAsyncThunk(
    "getAllTeam/fetchGetAllTeam",
    async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/teams`, {
                method: "GET"
            });
            return await response.json()
        } catch (error) {
            console.error(error);
        }
    });


const getSingleServicesFunc = createAsyncThunk(
    "getSingleServices/fetchGetSingleServices",
    async (input) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/product?id=${input}`, {
                method: "GET"
            });
            return await response.json()
        } catch (error) {
            console.error(error);
        }
    });

const getAllDemFunc = createAsyncThunk(
    "getAllDem/fetchGetAllDem",
    async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/demproducts`, {
                method: "GET"
            });
            return await response.json()
        } catch (error) {
            console.error(error);
        }
    });

const generalDataSlice = createSlice({
    name: "generalDataSlice",
    initialState,
    reducers: {
        setIsLogged: (state, action) => {
            state.isLogged = action.payload
        }
    },
    extraReducers: (builder) => {
        /* GET ALL FAIRS */
        builder.addCase(getAllTeamFunc.pending, (state, action) => {
            state.isLoadingTeam = true
        });
        builder.addCase(getAllTeamFunc.fulfilled, (state, action) => {
            state.isLoadingTeam = false;
            state.allTeam = action.payload;
        });
        builder.addCase(getAllTeamFunc.rejected, (state, action) => {
            state.isLoadingTeam = false;
            state.error = "errore nella GET di allTeams"
        });
        /* GET SINGLE SERVICE */
        builder.addCase(getSingleServicesFunc.pending, (state, action) => {
            state.isLoadingServices = true
        });
        builder.addCase(getSingleServicesFunc.fulfilled, (state, action) => {
            state.isLoadingServices = false;
            state.singleService = action.payload;
        });
        builder.addCase(getSingleServicesFunc.rejected, (state, action) => {
            state.isLoadingServices = false;
            state.error = "errore nella GET di allServices"
        });
        /* GET ALL DEM */
        builder.addCase(getAllDemFunc.pending, (state, action) => {
            state.isLoadingDem = true
        });
        builder.addCase(getAllDemFunc.fulfilled, (state, action) => {
            state.isLoadingDem = false;
            state.allDem = action.payload;
        });
        builder.addCase(getAllDemFunc.rejected, (state, action) => {
            state.isLoadingDem = false;
            state.error = "errore nella GET di allServices"
        });
    }
})



export const { setIsLogged } = generalDataSlice.actions;
export default generalDataSlice.reducer;
export { getAllTeamFunc, getSingleServicesFunc, getAllDemFunc }