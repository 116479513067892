import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { setReloadFetch } from '../states/reportState';
import { useDispatch, useSelector } from 'react-redux';
import { setEditingVisit, updateNewVisitFunc } from '../states/reportState';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import countriesIso2Arry from '../assets/json/countriesIso2Arry.json'

const ReportsVisitFormEdit = ({ user, fairName, fairYear }) => {

    const visitData = useSelector((state) => state.report.visitData);
    const isUpdatingVisit = useSelector((state) => state.report.isUpdatingVisit);
    const dispatch = useDispatch();

    const [companyName, setCompanyName] = useState(visitData.companyName);
    const [referent, setReferent] = useState(visitData.referent);
    const [email, setEmail] = useState(visitData.email);
    const [phone, setPhone] = useState(visitData.phone);
    const [countries, setCountries] = useState(visitData.countries);
    const [notes, setNotes] = useState(visitData.notes);
    const [digital, setDigital] = useState(visitData.digital);
    const [paper, setPaper] = useState(visitData.paper);
    const [events, setEvents] = useState(visitData.events);

    const [message, setMessage] = useState("");
    const [isEdited, setIsEdited] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(true);
    const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
    const [isCountrySelected, setIsCountrySelected] = useState(true);

    const [filteredCountry, setFilteredCountry] = useState(countriesIso2Arry.iso2);

    const updateNewVisit = async () => {

        const payload = {
            id: visitData.id,
            companyName: companyName,
            referent: referent,
            email: email,
            phone: phone,
            countries: countries,
            digital: digital,
            paper: paper,
            events: events,
            notes: notes,
            fonte: "Fiera",
            medio: `${fairName} ${fairYear}`,
            updatedBy: user
        };
        if (!companyName || !referent || !email) { setIsFormFilled(false); return };
        setIsFormFilled(true);//just to trigger form input filling alert
        dispatch(updateNewVisitFunc(payload));
        setIsEdited(true);//to avoid first open reload and reset of the form
        return

    };

    const filterCountries = (input) => {
        let filtered = countriesIso2Arry.iso2.filter((item) => item.toLowerCase().includes(input.toLowerCase()));
        setFilteredCountry(filtered);
    }

    useEffect(() => {
        if (!isUpdatingVisit && isEdited) {
            setCompanyName("");
            setReferent("");
            setEmail("");
            setPhone("");
            setDigital(1);
            setPaper(1);
            setEvents(1);
            setNotes("");
            setMessage("");
            dispatch(setEditingVisit(false))
            setIsEdited(false);
            dispatch(setReloadFetch());
        }
    }, [isUpdatingVisit])


    return (

        <div className='border border-warning p-3 mt-3 mb-5 rounded-5 mb-3 w-100'>
            <h2 className='text-warning fw-light'>Edit Visit</h2>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Company
                </InputGroup.Text>
                <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setCompanyName(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={companyName}
                    required
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Referent
                </InputGroup.Text>
                <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setReferent(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={referent}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    @
                </InputGroup.Text>
                <Form.Control
                    type='email'
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setEmail(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={email}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    <i className="bi bi-telephone-fill"></i>
                </InputGroup.Text>
                <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setPhone(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={phone}
                />
            </InputGroup>

            <div>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="inputGroup-sizing-default">
                        <i className="bi bi-globe-americas"></i> {/* country */}
                    </InputGroup.Text>
                    <Form.Control
                        className={isCountrySelected?"":"border border-danger text-danger"}
                        placeholder="select a country from the dropdown menu..."
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={(e) => { setCountries(e.target.value); filterCountries(e.target.value) }}
                        onClick={() => {setIsCountryDropdownOpen(true); setIsCountrySelected(false)}}
                        value={countries}
                    />
                </InputGroup>
                {
                    isCountryDropdownOpen ?
                        <div className='border bg-light w-100 p-2 px-3 myScrollY' style={{ maxWidth: "350px", zIndex: "1", maxHeight: "300px" }}>
                            <p className='m-1 myCursor text-secondary' onClick={() => { setCountries(""); setIsCountryDropdownOpen(false); setIsCountrySelected(true) }}>none</p>
                            <hr />
                            {
                                filteredCountry && filteredCountry.map((el) => {
                                    return <p className='m-1 myCursor' onClick={()=>{setCountries(el.split(":")[1]); setIsCountryDropdownOpen(false); setIsCountrySelected(true)}}>{`${el.split(":")[1]} (${el.split(":")[0]})`}</p>
                                })
                            }
                        </div> : null
                }
            </div>

            <hr className='text-light' />

            <div className='d-flex justify-content-between'>
                <span className="mb-3">
                    <h6 className='text-light m-0 mb-1'> Digital</h6>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        onClick={(e) => { setDigital(e.target.checked ? 1 : 0) }}
                        defaultChecked={digital ? "cheked" : ""}
                    />
                </span>
                <span className="mb-3">
                    <h6 className='text-light m-0 mb-1'> Paper</h6>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        onClick={(e) => { setPaper(e.target.checked ? 1 : 0) }}
                        defaultChecked={paper ? "cheked" : ""}
                    />
                </span>
                <span className="mb-3 ">
                    <h6 className='text-light m-0 mb-1'> Events</h6>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        onClick={(e) => { setEvents(e.target.checked ? 1 : 0) }}
                        defaultChecked={events ? "cheked" : ""}
                    />
                </span>
            </div>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Notes
                </InputGroup.Text>
                <Form.Control
                    as="textarea"
                    rows="4"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setNotes(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={notes}
                />
            </InputGroup>
            <Button className='mt-1 w-100 text-light' variant="warning" size="sm" onClick={() => { updateNewVisit() }}>
                {
                    isUpdatingVisit ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> :
                        <i className='bi bi-arrow-repeat '></i>
                } Update
            </Button>
            <Button className='mt-1 w-100 text-light' variant="secondary" size="sm" onClick={() => { dispatch(setEditingVisit(false)) }}>Cancel</Button>
            <div className='mt-2'>{!isFormFilled ? <Alert variant="danger">Fill at least Company, Email and Referent and make sure to selct the country from the dropdown menu!</Alert> : ""}</div>
            <p className='text-light mt-2'>{message}</p>
        </div>
    )
}

export default ReportsVisitFormEdit