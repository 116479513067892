import { React, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import emagazineJson from '../../assets/json/emagazines.json';
import mic from '../../assets/images/pressOffice/microphone.jpg';
import newsPress from '../../assets/images/pressOffice/newsletterPressOffice.jpg';
import mailbox from '../../assets/images/pressOffice/mailbox.jpg';

const PressOfficePage = () => {

  const [loopIndex, setLoopIndex] = useState(1);

  const counter = (input) => {
    if (loopIndex === 7) {
      setLoopIndex(0)
    } else {
      input ? setLoopIndex(loopIndex + 1) : setLoopIndex(loopIndex - 1)
    }
  }

  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${mic})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light d-flex flex-column align-items-center'>
            <h1 className='montserrat-alternates-bold'>Press Office</h1>
            <h2 className='font-nycd text-info my-5'>FIND A PROFESSIONAL AND UPSTANDING VOICE</h2>
            <h3 className='fw-light myMaxWidth1600'>Corporate communications have found a new ally: our press office services are tools designed to help you promote your business in the most professional and reputable way.
              We offer different services to best fit your needs:
            </h3>
          </div>
        </div>


        <div className='py-4'>
          <div className='d-flex flex-column align-items-center'>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
            <h1 className=' text-center text-secondary mt-5'>E-Magazines</h1>
            <h2 className='text-secondary fw-light mt-4 mx-5 text-center myMaxWidth1200'>E-magazine: replicating the look and idea of a newspaper, a digital magazine is a page containing different news and information regarding your business, a classy yet original way of highlighting your company and its products.</h2>
          </div>
          <div className='position-relative pressArrowCarousel'>
            <div className='d-flex justify-content-between'>
              {loopIndex === 0 ? <i className="bi bi-caret-left-fill ms-5 display-3 text-light" ></i> : <i className="bi bi-caret-left-fill ms-5 display-3 myCursor text-secondary" onClick={() => counter(0)} ></i>}
              <i className="bi bi-caret-right-fill me-5 display-3 myCursor text-secondary" onClick={() => counter(1)} ></i>
            </div>
          </div>
          <div className='infinite_carousel d-flex justify-content-center align-items-center'>
            {
              emagazineJson && emagazineJson.map((el, index) => {
                               
                if ((index === loopIndex || index === loopIndex - 1 || index === loopIndex + 1)) {
                  return <div className='p-4 m-2 border' style={{ background: "#fff" }}><a href={el.url} target='_blank'><img className={`${index === loopIndex ? "myMagazineHeightMax" : "myMagazineHeightMin"}`} src={require(`../../assets/images/pressOffice/${el.imgName}`)} alt="img" /* style={{ maxHeight: `${index === loopIndex ? "400px" : "200px"}` }} */ /></a></div>
                }
                
              })
            }
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>


        <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${newsPress})` }}>
          <div className='py-5 my-5 text-light myBgTransparent60 d-flex flex-column align-items-center'>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
            <h1 className='mt-4 text-center'>Newsletter Press Office</h1>
            <h2 className='fw-light my-4 mx-5 text-center myMaxWidth1400'>If you’re searching for a more formal and less explicit way to advertise your products, highlighting their complexity and innovation, our newsletter “press office” is what you need. Combining articles from various realities, this digital publication takes the form of a press release that shares news and information compiled with a scientific approach and provides a space where to promote your business moving from a reliable and institutional perspective.</h2>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
          </div>
        </div>


        <div className='py-4 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <h1 className=' text-secondary'>B2B press release</h1>
          <div className='d-flex justify-content-center align-items-center flex-wrap'>
            <h2 className='text-secondary fw-light mt-4 mx-5 myMaxWidth700'>Taking advantage of our vast database, this service comes in the shape of a paper letter that physically delivers your message to businesses in our network, for a press release with a more traditional feel.</h2>
            <img className='w-100 mt-5 myMaxWidth700' src={mailbox} alt="" />
          </div>
        </div>

        {/* my spacer */}
        <div style={{ height: "100px" }}></div>

      </div>

    </MainLayout>
  )
}

export default PressOfficePage