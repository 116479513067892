import React from 'react'
import { useDispatch } from 'react-redux';
import { setReloadFetch, setEditingVisit, setVisitData } from '../states/reportState';
import { useState } from 'react';
import digitalLogo from '../assets/logo/digitalLogoSM.png'
import paperLogo from '../assets/logo/paperLogoSM.png'
import eventsLogo from '../assets/logo/eventsLogoSM.png'

const VisitsCard = ({ el }) => {

    const dispatch = useDispatch();

    const deleteVisit = async () => {
        try {
            await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/deletevisit?id=${el.id}`, {
                method: "DELETE",
                headers: { "Authorization": localStorage.getItem("tkn") }
            });
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div className='border border-warning bg-dark text-light m-1 p-3 py-2 rounded-4' style={{ cursor: "pointer" }}>
            <div>
                <h1 className='mb-2'>{el.companyName}</h1>
                <div className='d-flex flex-wrap mb-3'>
                    <div className='me-4'><i className="bi bi-person-fill me-2">{` ${el.referent}`}</i></div>
                    <div className='me-4'> <i className="bi bi-envelope-at-fill me-2">{` ${el.email}`}</i></div>
                    {el.phone ? <div className='me-4'> <i className='bi bi-telephone-fill me-2'>{` ${el.phone}`}</i></div> : ""}
                    {el.countries ? <div>{el.countries}</div> : ""}
                </div>

                <div className='d-flex'>
                    <p>Interested in: </p>
                    {el.digital ? <img className='ms-3' src={digitalLogo} alt="" style={{ height: "25px" }} /> : null}
                    {el.paper ? <img className='ms-3' src={paperLogo} alt="" style={{ height: "25px" }} /> : null}
                    {el.events ? <img className='ms-3' src={eventsLogo} alt="" style={{ height: "25px" }} /> : null}
                    {!el.digital && !el.paper && !el.events ? <i className="bi bi-x-lg text-danger ms-3"></i> : null}
                </div>

                {el.notes.length > 1 ? <div><span className='text-warning'>Notes: </span><i>{el.notes}</i></div> : null}
            </div>

            <hr className='mt-1' />

            <div className='d-flex justify-content-between'>
                <span className='p-1' onClick={() => { dispatch(setEditingVisit(true)); dispatch(setVisitData(el)) }}>
                    <i className='bi bi-pencil-fill text-light'></i>
                </span>
                <i className='fw-light text-secondary'>
                    {el.createdBy}
                </i>
                <span className='p-1' onClick={() => { window.confirm("are you sure?"); deleteVisit(); dispatch(setReloadFetch()); }}>
                    <i className='bi bi-trash-fill text-danger'></i>
                </span>
            </div>

        </div>
    )
}

export default VisitsCard