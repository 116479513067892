import React from 'react';
import { nanoid } from 'nanoid';
//this components allows to visualize the rate 
function ViewStarsRating({rate}) {
    return (
        <>
            {
                [...Array(5)].map((el, index)=>{
                    if(rate >= (index+1)){
                        return <i className="bi bi-star-fill" key={nanoid()}></i>
                    }else{
                        return <i className="bi bi-star" key={nanoid()}></i>
                    }
                })
            }
            <span className='ms-2'>
                {`${rate}/5`}
            </span>
            
        </>
    )
}

export default ViewStarsRating