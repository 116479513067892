import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getSingleServicesFunc } from '../states/generalDataState';
import MainLayout from '../layouts/MainLayout';
import Spinner from 'react-bootstrap/Spinner';

const ServiceFocusCard = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const singleService = useSelector((state) => state.generalData.singleService);
    const isLoadingServices = useSelector((state) => state.generalData.isLoadingServices);

    useEffect(() => {
        dispatch(getSingleServicesFunc(id))
    }, [id])

    return (
        <MainLayout>
            <div>
                {
                    isLoadingServices ?
                        <div className=' d-flex justify-content-center' style={{ height: "calc(100vh - 59px)" }}>
                            <div className='h-50 w-100 d-flex align-items-center justify-content-center'>
                                <Spinner animation="border" variant="light" />
                            </div>
                        </div> :
                        singleService && <div className='d-flex justify-content-center'>
                            <div className='my-4 mx-3 bg-dark rounded-5 p-4 myMaxWidth700' style={{ border: `2px solid ${singleService[0].category === "events" ? '#e82283' : singleService[0].category === "paper" ? '#14a7ad' : '#17365d'}` }}>
                                <h3 className='text-light'>{singleService[0].name}</h3>
                                <p className='text-light'>{singleService[0].description}</p>
                            </div>
                        </div>
                }
            </div>
        </MainLayout>

    )
}

export default ServiceFocusCard