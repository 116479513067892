import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { setReloadFetch, createNewVisitFunc } from '../states/reportState';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import countriesIso2Arry from '../assets/json/countriesIso2Arry.json'

const ReportsVisitForm = ({ user, idfair, fairName, fairYear }) => {

    const [companyName, setCompanyName] = useState("");
    const [referent, setReferent] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [countries, setCountries] = useState("");
    const [notes, setNotes] = useState("");
    const [digital, setDigital] = useState(1);
    const [paper, setPaper] = useState(1);
    const [events, setEvents] = useState(1);
    const isPostingVisit = useSelector((state) => state.report.isPostingVisit);

    const [message, setMessage] = useState("");
    const [isEdited, setIsEdited] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(true);
    const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
    const [isCountrySelected, setIsCountrySelected] = useState(true);

    const [filteredCountry, setFilteredCountry] = useState(countriesIso2Arry.iso2);

    const dispatch = useDispatch();

    const createNewVisit = async () => {

        const payload = {
            idfair: idfair,
            companyName: companyName,
            referent: referent,
            email: email,
            phone: phone,
            countries: countries,
            digital: digital,
            paper: paper,
            events: events,
            notes: notes,
            fonte: "Fiera",
            medio: `${fairName} ${fairYear}`,
            createdBy: user
        };
        if (!companyName || !referent || !email || !isCountrySelected) { setIsFormFilled(false); return };
        setIsFormFilled(true);//just to trigger form input filling alert
        dispatch(createNewVisitFunc(payload))
        setIsEdited(true);//to avoid first open reload and reset of the form
        return
    };

    const filterCountries = (input) => {
        let filtered = countriesIso2Arry.iso2.filter((item) => item.toLowerCase().includes(input.toLowerCase()));
        setFilteredCountry(filtered);
    }


    useEffect(() => {
        if (!isPostingVisit && isEdited) {
            setCompanyName("");
            setReferent("");
            setEmail("");
            setPhone("");
            setDigital(1);
            setPaper(1);
            setEvents(1);
            setNotes("");
            setMessage("");
            setIsEdited(false);
            dispatch(setReloadFetch());
        }
    }, [isPostingVisit])


    return (

        <div className='border border-info p-3 rounded-5 mb-3'>
            <h2 className='text-info fw-light'>Add a Visit</h2>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    <i className="bi bi-buildings-fill"></i>{/* Company */}
                </InputGroup.Text>
                <Form.Control
                    placeholder="Example Srl"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setCompanyName(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={companyName}
                    required
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    <i className="bi bi-person-fill"></i>{/* Referent */}
                </InputGroup.Text>
                <Form.Control
                    placeholder="Mario Rossi"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setReferent(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={referent}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    @ {/* email */}
                </InputGroup.Text>
                <Form.Control
                    type='email'
                    placeholder="mariorossi@example.com"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setEmail(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={email}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    <i className="bi bi-telephone-fill"></i> {/* phone */}
                </InputGroup.Text>
                <Form.Control
                    placeholder="3331466352"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setPhone(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={phone}
                />
            </InputGroup>

            <div>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="inputGroup-sizing-default">
                        <i className="bi bi-globe-americas"></i> {/* country */}
                    </InputGroup.Text>
                    <Form.Control
                        className={isCountrySelected?"":"border border-danger text-danger"}
                        placeholder="select a country from the dropdown menu..."
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={(e) => { setCountries(e.target.value); filterCountries(e.target.value) }}
                        onClick={() => {setIsCountryDropdownOpen(true); setIsCountrySelected(false)}}
                        value={countries}
                    />
                </InputGroup>
                {
                    isCountryDropdownOpen ?
                        <div className='border bg-light w-100 p-2 px-3 myScrollY' style={{ maxWidth: "350px", zIndex: "1", maxHeight: "300px" }}>
                            <p className='m-1 myCursor' onClick={() => { setCountries(""); setIsCountryDropdownOpen(false); setIsCountrySelected(true) }}>none</p>
                            {
                                filteredCountry && filteredCountry.map((el) => {
                                    return <p className='m-1 myCursor' onClick={() => { setCountries(el.split(":")[1]); setIsCountryDropdownOpen(false); setIsCountrySelected(true) }}>{`${el.split(":")[1]} (${el.split(":")[0]})`}</p>
                                })
                            }
                        </div> : null
                }
            </div>


            <hr className='text-light' />

            <div className='d-flex justify-content-between'>
                <div className="mb-3">
                    <h6 className='text-light m-0 mb-1'> Digital</h6>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Stand"
                        onClick={(e) => { setDigital(e.target.checked ? 1 : 0); setIsCountryDropdownOpen(false) }}
                        defaultChecked={digital ? "cheked" : ""}
                    />
                </div>
                <div className="mb-3">
                    <h6 className='text-light m-0 mb-1'> Paper</h6>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Stand"
                        onClick={(e) => { setPaper(e.target.checked ? 1 : 0); setIsCountryDropdownOpen(false) }}
                        defaultChecked={paper ? "cheked" : ""}
                    />
                </div>
                <div className="mb-3 ">
                    <h6 className='text-light m-0 mb-1'> Events</h6>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Stand"
                        onClick={(e) => { setEvents(e.target.checked ? 1 : 0); setIsCountryDropdownOpen(false) }}
                        defaultChecked={events ? "cheked" : ""}
                    />
                </div>
            </div>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Notes
                </InputGroup.Text>
                <Form.Control
                    as="textarea"
                    rows="4"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setNotes(e.target.value) }}
                    onClick={() => setIsCountryDropdownOpen(false)}
                    value={notes}
                />
            </InputGroup>
            <Button className='mt-1 w-100 text-light' variant="info" size="sm" onClick={() => { createNewVisit() }}>
                {
                    isPostingVisit ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> :
                        <i className='bi bi-plus-lg '></i>
                } Add
            </Button>
            <div className='mt-2'>{!isFormFilled ? <Alert variant="danger">Fill at least Company, Email and Referent and make sure to selct the country from the dropdown menu!</Alert> : ""}</div>
            <p className='text-light mt-2'>{message}</p>
        </div>
    )
}

export default ReportsVisitForm