import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import videoInterview from '../../assets/images/videoInterview/journalistInterview.jpg';
import mrkCampaign from '../../assets/images/videoInterview/digital-campaign.png';
import brandFace from '../../assets/images/videoInterview/face-recognition.png';
import format4K from '../../assets/images/videoInterview/4k-film.png';
import volume from '../../assets/images/videoInterview/volume.png';
import editing from '../../assets/images/videoInterview/film-editing.png';
import editingTeam from '../../assets/images/videoInterview/teamEditing.jpg';
import ita from '../../assets/images/productVideo/italy.png';
import eng from '../../assets/images/productVideo/united-kingdom.png';
import youtube from '../../assets/images/videoInterview/youtube.png';

const VideoInterviewsPage = () => {
  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${videoInterview})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>Video Interview</h1>
            <h2 className='font-nycd text-info my-5'>EVERYONE HAS A STORY TO TELL. LET YOURS BE THE NEXT</h2>
            <h3 className='fw-light'>Tell your story – or that of your brand – and leave a long-lasting impression.</h3>
          </div>
        </div>

        <div className='bg-dark'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex flex-wrap justify-content-center gap-1 mb-5'>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Wq7e3yxp7N8?si=5s4axq7uqu7Pv5Zl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/ewTHpcdR0Os?si=YQ6bP9aO5X5WHRky" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/K4rUv8wTgE8?si=Svx4bBRkO6ULQpcH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/oZ-OKKzKkvk?si=n7SHFznJB2X6L74R" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/WMGydb4pLHc?si=PjC4aGp-Xadf83Zv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth700'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Bs5jBTw0g8o?si=CHBen1YaDArZfZl7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className='my-5 py-5 d-flex justify-content-center '>
            <a className='noDecoration d-flex justify-content-center align-items-center gap-3' href="https://www.youtube.com/@InfodentSrl/videos" target='_blank'>
              <img className='myMaxWidth60' src={youtube} alt="" />
              <h2 className='m-0'>Watch all our interviews</h2>
            </a>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

      

        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth200' src={mrkCampaign} alt="" />
            <h2 className='text-secondary myMaxWidth700'>From a strategic point of view, videos are an incredible tool to boost your marketing campaigns and constitute nowadays <b>the most effective way to be heard by the industry.</b></h2>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <h2 className='myMaxWidth700'>Video interviews offer a fantastic opportunity to give your brand a face and an identity and get <b>easily recognized by your partners, clients, and competitors.</b></h2>
            <img className='myMaxWidth200' src={brandFace} alt="" />
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 pt-5 d-flex justify-content-center gap-4'>
            <img className='myMaxWidth80' src={format4K} alt="img" />
            <img className='myMaxWidth80' src={volume} alt="img" />
            <img className='myMaxWidth80' src={editing} alt="img" />
          </div>
          <h2 className='text-secondary text-center myMaxWidth1200'>Recorded in <b>4K</b> with top quality equipment and <b>professional audio</b> to ensure great results, video interviews can be shot during the main trade fairs, at company events, or it is also possible to arrange a day of shooting at your company’s headquarters. </h2>
          <h1 className='font-nycd mt-5 mb-4 text-center fw-light text-info'>It’s your brand’s chance to shine in the spotlight.</h1>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${editingTeam})` }}>
          <div className='p-5 myBgTransparent60 text-light d-flex flex-column align-items-center'>
            <h2 className='fw-light my-5 myMaxWidth1200'>Everything else, from editing to the final touches of post-production, will be taken care by our team to meet you with the finished product in the shortest possible time.
              Enhance your chances to be seen and heard: choose videos to get your story out there.
            </h2>
            <div className='py-5 pt-5 d-flex justify-content-center gap-4'>
              <img className='myMaxWidth50' src={ita} alt="img" />
              <img className='myMaxWidth50' src={eng} alt="img" />
            </div>
            <h2 className='fw-light mb-5 myMaxWidth1200'>Available in english and italian language.</h2>
          </div>
        </div>

      </div>
    </MainLayout>
  )
}

export default VideoInterviewsPage