import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import tabloidBg from '../../assets/images/paperMagazine/tabloidBg.jpg';
import trolley from '../../assets/images/scientificBooks/trolley.png';
import students from '../../assets/images/scientificBooks/audience.png';
import libroNardi from '../../assets/images/scientificBooks/libro_nardi.jpg';
import libroFantozzi from '../../assets/images/scientificBooks/libro_fantozzi.jpg';
import libroPapa from '../../assets/images/scientificBooks/libro_papa.jpg';
import libroUnid from '../../assets/images/scientificBooks/libro_unid.png';
import reading from '../../assets/images/scientificBooks/reading.png';
import speaker from '../../assets/images/scientificBooks/loudspeaker.png';

const ScientificBookPage = () => {
  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${tabloidBg})` }}>
          <div className='d-flex flex-column align-items-center px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>Scientific Book</h1>
            <h2 className='font-nycd text-info my-5'>OUR KNOWLEDGE IS IMPORTANT. SHARE IT WITH THE INDUSTRY.</h2>
            <h3 className='fw-light myMaxWidth1400'>Although the world of communication is evolving and changing rapidly, books and printed releases are still the most professional way of sharing your knowledge and expertise with your audience. </h3>
          </div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <h1 className='text-secondary'>Our Last Publications</h1>
          <div className='py-5 mb-5 d-flex justify-content-center flex-wrap gap-5'>
            <div className='border myMaxWidth500 myBgWhite'>
              <img className='img-fluid' src={libroFantozzi} alt="img" />
              <div className='p-4'>
                <h4 className='fw-light'>Il management del benessere del clinico: problem solving della criticità nella pratica odontoiatrica.</h4>
                <hr />
                <h6 className='fw-light text-center text-info'>Giulia Fantozzi, Federica Donnini, Roberta Scarci, Francesca Bergamante, Roberta Grassi, Gianna Maria Nardi</h6>
              </div>
            </div>
            <div className='border myMaxWidth500 myBgWhite'>
              <img className='img-fluid' src={libroPapa} alt="img" />
              <div className='p-4'>
                <h4 className='fw-light'>Ozonoterapia in odontoiatria. Protocolli di pratica clinica basati sulle evidenze.</h4>
                <hr />
                <h6 className='fw-light text-center text-info'>Gianna Maria Nardi, Giulio Papa, Roberta Grassi, Marta Mazur, Felice Roberto Grassi</h6>
              </div>
            </div>
            <div className='border myMaxWidth500 myBgWhite'>
              <img className='img-fluid' src={libroNardi} alt="img" />
              <div className='p-4'>
                <h4 className='fw-light'>Approccio integrato e personalizzato al paziente affetto da patologie gengivali non indotte da placca e tartaro.</h4>
                <hr />
                <h6 className='fw-light text-center text-info'>Felice Roberto Grassi, Gianna Maria Nardi, Roberta Grassi, Massimo Petruzzi</h6>
              </div>
            </div>
            <div className='border myMaxWidth500 myBgWhite'>
              <img className='img-fluid' src={libroUnid} alt="img" />
              <div className='p-4'>
                <h4 className='fw-light'>I quaderni dell'igenista dentale 2022 - N°01.</h4>
                <hr />
                <h6 className='fw-light text-center text-info'>UNID - Unione Nazionale Igenisti Dentali</h6>
              </div>
            </div>
          </div>

          <h2 className='text-center text-secondary myMaxWidth1200'>From inception through fruition, publishing your book with us means having the <b>support</b> of an <b>experienced team</b> who has been actively involved in printing and sharing ideas and information within the dental and medical industry for over three decades.</h2>
          <div className='py-5 pt-5 d-flex justify-content-center align-items-center gap-4'>
            <img className='myMaxWidth200 mt-5' src={students} alt="img" />
          </div>
          <h1 className='font-nycd my-5 text-center fw-light text-info'>Let your ideas make a difference!</h1>

          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-column gap-5'>
            <img className='myMaxWidth200' src={reading} alt="" />
            <div>
              <h1 className='myMaxWidth1200 text-center fw-light'>With the ultimate goal of publication, our editorial services are designed for two different categories:</h1>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center myMaxWidth1400'>
              <h2 className='my-5'>	<h1><b>Authors </b>:</h1> we help professionals who are seeking to publish their knowledge by finding sponsors among companies and associations to fund the project and cover design and printing expenses.</h2>
              <h2> <h1><b>Companies & Associations </b>:</h1> we help businesses who are looking to release publications about their products, reports, monographs, or other kinds of works, by providing editorial assistance, including authors who can take care of writing.</h2>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth150' src={speaker} alt="" />
            <div>
              <h2 className='myMaxWidth700 text-secondary'>The final product can be <b>promoted</b> and <b>distributed</b> through our channels to a vast audience, or delivered to you so you can take care of the following steps according to your preferences. </h2>
            </div>
            <img className='myMaxWidth150' src={trolley} alt="" />
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>


      </div>
    </MainLayout>
  )
}

export default ScientificBookPage