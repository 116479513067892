import React, { useEffect } from 'react';
import { useState } from 'react';
import 'js-circle-progress';  /* https://www.npmjs.com/package/js-circle-progress */
import DatabaseStats from './DatabaseStats';
import DatabaseHowToDo from './DatabaseHowToDo';
import dbStats from "../assets/json/databaseStats.json";

/* lottie */
import Lottie from 'lottie-react';
import worldAnimation from '../assets/animations/animation_ln2wwrmb.json';
import dotsAnimation from '../assets/animations/dotsSpinner.json';
import networkFriends from '../assets/animations/networkFriends.json';
import rocket from '../assets/animations/rocket.json';
import database from '../assets/animations/database.json';
import cloud from '../assets/animations/cloudDatabase.json';

import { useSelector } from 'react-redux';
import { setProductDemOnFocus } from '../states/navigationState';
import { useDispatch } from 'react-redux';


const _OurDatabase = () => {

    const [stepPage, setStepPage] = useState(0);
    const productDemOnFocus = useSelector((state) => state.navigation.productDemOnFocus);
    const dispatch = useDispatch();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: dotsAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMi slice'
        }
    }


    return (

        <>

            <div className='position-absolute w-100 px-3' style={{ left: "50vw", top: "9vh", transform: "translateX(-50%)", maxWidth: "700px" }}>
                {
                    stepPage !== 2 ?
                        <div className=" position-relative" style={{ width: "100px", height: "100px", left: "50%", transform: "translateX(-50%)" }}>{/* animTransIn */}
                            <Lottie animationData={cloud} options={defaultOptions} />
                        </div> :
                        <div className='mt-4'></div>
                }
                <div className='pt-3'>
                    <div className='border border-secondary rounded-5 p-4 myBgWhite' >
                        {
                            stepPage === 0 ?
                                <>
                                    <h3 className='fw-normal ps-2 text-transparent' style={{ borderLeft: "4px solid #e82283" }}>Our Database</h3>
                                    <p className='text-transparent'>
                                        We daily update and feed our database, populated by 70K Dealers, Dentists and Dental Manufacturer.
                                    </p>
                                    <hr />
                                    <div className='mb-2'>
                                        <div className=' d-flex align-items-center'><i className="bi bi-circle-fill ms-2 me-2" style={{ fontSize: ".4rem", color: "#e82283" }}></i><i className='fw-bold'>Dealer - 28K</i></div>
                                        <div className='rounded-5 d-flex align-items-center' style={{ border: "10px solid #333", height: "10px", width: "100%" }}>
                                            <div className='rounded-5' style={{ border: "4px solid #e82283", height: "4px", width: "40%" }}></div>
                                        </div>
                                    </div>
                                    <div className='mb-2'>
                                        <div className=' d-flex align-items-center'><i className="bi bi-circle-fill ms-2 me-2" style={{ fontSize: ".4rem", color: "#14a7ad" }}></i><i className='fw-bold'>Manufacturers - 31K</i></div>
                                        <div className='rounded-5 d-flex align-items-center' style={{ border: "10px solid #333", height: "10px", width: "100%" }}>
                                            <div className='rounded-5' style={{ border: "4px solid #14a7ad", height: "4px", width: "45%" }}></div>
                                        </div>
                                    </div>
                                    <div className='mb-2'>
                                        <div className=' d-flex align-items-center'><i className="bi bi-circle-fill ms-2 me-2" style={{ fontSize: ".4rem", color: "#aaa" }}></i><i className='fw-bold'>Professionals - 11K</i></div>
                                        <div className='rounded-5 d-flex align-items-center' style={{ border: "10px solid #333", height: "10px", width: "100%" }}>
                                            <div className='rounded-5' style={{ border: "4px solid #eee", height: "4px", width: "15%" }}></div>
                                        </div>
                                    </div>
                                </> : stepPage === 1 ?
                                    <DatabaseStats
                                        dbStats={dbStats}
                                    /> :
                                    <DatabaseHowToDo />

                        }

                        <div className='mt-1 pt-3 text-primary' style={{ cursor: "pointer" }}>

                            {//navigation buttons
                                stepPage === 0 ?
                                    <div className='d-flex justify-content-center' onClick={() => setStepPage(1)}>
                                        <p className='mb-0'>statistics</p>
                                        <div><i className="bi bi-graph-up-arrow ms-2"> </i></div>
                                    </div> : stepPage === 1 ?
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <div className='d-flex align-items-center' onClick={() => setStepPage(0)}>
                                                <i className="bi bi-arrow-left me-2 text-secondary"> </i>
                                                <p className='mb-0 text-secondary'>back</p>
                                            </div>
                                            <div className='d-flex align-items-center' onClick={() => setStepPage(2)}>
                                                <p className='mb-0 ms-4'>how to use it</p>
                                                <div><i className="bi bi-arrow-right ms-2"> </i></div>
                                            </div>
                                        </div> : !productDemOnFocus ?
                                            <div className='d-flex justify-content-end align-items-center' onClick={() => setStepPage(1)}>
                                                <i className="bi bi-arrow-left me-2 text-secondary"> </i>
                                                <p className='mb-0 text-secondary'>back</p>
                                            </div> :
                                            <div className='d-flex justify-content-end align-items-center' onClick={() => dispatch(setProductDemOnFocus(false))}>
                                                <i className="bi bi-arrow-left me-2 text-secondary"> </i>
                                                <p className='mb-0 text-secondary'>return</p>
                                            </div>
                            }

                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default _OurDatabase