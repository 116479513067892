import { React, useEffect, useState } from 'react';
import { CircularProgressBar } from "react-percentage-bar";
import { LinearProgressBar } from "react-percentage-bar";/* https://www.npmjs.com/package/react-percentage-bar */
import { nanoid } from 'nanoid';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


const DatabaseStats = ({dbStats}) => {

    const [typeDB, setTypeDB] = useState([0, "Dental Database"]);

    return (
        <div>
            <div className='d-flex align-items-center gap-3'>
                <h3 className='fw-normal text-transparent' >Statistics</h3>
                <div className='shadow-sm dbButton'>
                    <DropdownButton
                        as={ButtonGroup}
                        key={0}
                        id="dropdown-button-drop-0"
                        size="sm"
                        variant="light"
                        title={typeDB[1]}
                    >
                        <Dropdown.Item key="1" onClick={() => setTypeDB([0, "Dental Database"])}>Dental Database</Dropdown.Item>
                        <Dropdown.Item key="2" onClick={() => setTypeDB([1, "Medical Database"])}>Medical Database</Dropdown.Item>
                        <Dropdown.Item key="3" onClick={() => setTypeDB([2, "Dental Italy Database"])}>Dental Italy Database</Dropdown.Item>
                        <Dropdown.Item key="4" onClick={() => setTypeDB([3, "Dentists Italy Database"])}>Dentists Italy Database</Dropdown.Item>
                        <Dropdown.Item key="5" onClick={() => setTypeDB([4, "Technicians Italy DB"])}>Technicians Italy DB</Dropdown.Item>
                        <Dropdown.Item key="6" onClick={() => setTypeDB([5, "ASO Italy Database"])}>ASO Italy Database</Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>

            <div>
                {
                    <div>
                        <div className='mt-1'>
                            <div className='d-flex align-items-center rounded-5' style={{ border: "6px solid #17365d",  width: "100%", height: "10px"}}>
                                <div className='rounded-5' style={{ border: "2px solid #e82283", height: "3px", width: `${Math.round((dbStats[typeDB[0]].nContacts / 70000) * 100)}%`}}></div>
                            </div>
                            <p className='mb-0'><b>{Math.round((dbStats[typeDB[0]].nContacts / 70000) * 1000) / 10} % </b><i>of our Database</i></p>
                        </div>
                        <i className='mb-2'> *average made on <b>{dbStats[typeDB[0]].nSent / 1000}K</b> submissions</i>
                    </div>
                }
            </div>

            <div className='my-4 d-flex justify-content-center gap-3 flex-wrap'>

                {
                    dbStats[typeDB[0]].stats.map((el, index) => {

                        return <div className='d-flex justify-content-center'>
                            <div className='d-flex flex-column align-items-center'>
                                <CircularProgressBar
                                    key={nanoid()}
                                    size={".2rem"}
                                    radius={"2rem"}
                                    roundLineCap={true}
                                    styles="solid"
                                    text={index === 3 ? "up to" : null}
                                    color={el.color}
                                    percentage={el.percentage}
                                />
                                <i>{el.description}</i>
                            </div>
                        </div>

                    })
                }
            </div>
        </div>
    )
}

export default DatabaseStats