import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentPage: 0,
    productDemOnFocus: false,
    serviceCategory: "All Services"
};

const navigationSlice = createSlice({
    name: "navigationSlice",
    initialState,
    reducers: {
        setProductDemOnFocus: (state, action) => {
            state.productDemOnFocus = action.payload
        },
        setServiceCategory: (state, action) => {
            state.serviceCategory = action.payload
        }
    }
})




export const { setProductDemOnFocus, setServiceCategory } = navigationSlice.actions;
export default navigationSlice.reducer;
/* export {  } */