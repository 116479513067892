import HomePage from './pages/HomePage';
import ErrorPage from "./pages/ErrorPage";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//fonts
import './style/App.css';
import './style/MyCustomStyles.css';
import './style/Fonts.css';
//pages
import AboutUsPage from "./pages/AboutUsPage";
import OurServicesPage from "./pages/OurServicesPage";
import OurDatabasePage from "./pages/OurDatabasePage";
import OurStrategyPage from "./pages/OurStrategyPage";
import ContactUsPage from "./pages/ContactUsPage";
import LoginPage from "./pages/LoginPage";
import ServiceFocusCard from './components/ServiceFocusCard';
//pages services
import AnimationsPage from './pages/services/AnimationsPage';
import CoExhibitionPage from './pages/services/CoExhibitionPage.jsx';
import CoursesWebinarPage from './pages/services/CoursesWebinarPage';
import DemPage from './pages/services/DemPage.jsx.jsx';
import EventsOrganizzationPage from './pages/services/EventsOrganizzationPage.jsx';
import LeafletPage from './pages/services/LeafletPage.jsx';
import PaperMagazinesPage from './pages/services/PaperMagazinesPage.jsx';
import ProfessionalVideoPage from './pages/services/ProfessionalVideoPage.jsx';
import ScientificBookPage from './pages/services/ScientificBookPage.jsx';
import ShowcasePage from './pages/services/ShowcasePage.jsx';
import SocialMediaPage from './pages/services/SocialMediaPage.jsx';
import VideoInterviewsPage from './pages/services/VideoInterviewsPage.jsx';
import PressOfficePage from './pages/services/PressOfficePage.jsx';
import CookiesPage from './pages/CookiesPage.jsx';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route path='/aboutus' element={<AboutUsPage />} />
        <Route path='/ourservices' element={<OurServicesPage />} />
        <Route path='/ourservices/focuscard/:id' element={<ServiceFocusCard/>} />

        <Route path='/ourservices/animations' element={<AnimationsPage />} />
        <Route path='/ourservices/coexhibiton' element={<CoExhibitionPage />} />
        <Route path='/ourservices/courseswebinar' element={<CoursesWebinarPage />} />
        <Route path='/ourservices/dem' element={<DemPage />} />
        <Route path='/ourservices/eventsorganizzation' element={<EventsOrganizzationPage />} />
        <Route path='/ourservices/leaflet' element={<LeafletPage />} />
        <Route path='/ourservices/papermagazines' element={<PaperMagazinesPage />} />
        <Route path='/ourservices/professionalvideo' element={<ProfessionalVideoPage />} />
        <Route path='/ourservices/scientificbooks' element={<ScientificBookPage />} />
        <Route path='/ourservices/showcase' element={<ShowcasePage />} />
        <Route path='/ourservices/socialmedia' element={<SocialMediaPage />} />
        <Route path='/ourservices/videointerview' element={<VideoInterviewsPage />} />
        <Route path='/ourservices/pressoffice' element={<PressOfficePage />} />

        <Route path='/cookies' element={<CookiesPage />} />
        <Route path='/ourdatabase' element={<OurDatabasePage />} />
        <Route path='/ourstrategy' element={<OurStrategyPage />} />
        <Route path='/contactus' element={<ContactUsPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
