import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import bgTrade from '../../assets/images/coExhibition/bgTradeShow.jpg';
import place from '../../assets/images/leaflet/check.png';
import moneySaving from '../../assets/images/leaflet/deposit.png';
import leaflet from '../../assets/images/leaflet/leaflet.png';
import spreading from '../../assets/images/leaflet/spreading.png';

const LeafletPage = () => {
  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${bgTrade})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>Leaflet Distribution</h1>
            <h2 className='font-nycd text-info my-5'>LET YOUR BRAND TRAVEL THE WORLD</h2>
            <h3 className='fw-light'>You don’t need to attend all the big events out there: we’ll do it for you.</h3>
          </div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 pt-5 d-flex justify-content-center align-items-center gap-4'>
            <img className='myMaxWidth80' src={leaflet} alt="img" />
          </div>
          <h2 className='text-center myMaxWidth1200'>There is always so much going on in our industry. When you can’t physically be there, your brand can come along with us. Leaflet distribution is an efficient and time-and-money-saving way to make sure you never miss the chance of meeting your next client. </h2>
          <div className='py-5 pt-5 d-flex justify-content-center align-items-center gap-4'>
            <img className='myMaxWidth80' src={moneySaving} alt="img" />
          </div>
          <h1 className='font-nycd my-5 text-center fw-light text-info'>Save time and money with our Leaflet Distribution service! </h1>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='myMaxWidth200' src={spreading} alt="" />
            <div>
              <h2 className='myMaxWidth700'>Your flyers will be on display at our booth – which are always <b>well-located</b> at all major trade fairs worldwide – and <b>our staff will distribute</b> them to potential and interested clients, while also taking care of <b>collecting their business cards</b> and information and sending them right to your offices afterwards.</h2>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <div>
              <h2 className='myMaxWidth700 text-secondary'>Just as with our real showcase services, leaflet distribution allows your label and products to <b>be where the industry meets and connects</b>: the perfect occasion for testing the waters in new areas or reinforcing your presence in already-known markets.</h2>
            </div>
            <img className='myMaxWidth150' src={place} alt="" />
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>


      </div>
    </MainLayout>
  )
}

export default LeafletPage