import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    isLogged: false,
    isSuperUser: false,
    isLoading: false,
    error: ""
};

const loginFunc = createAsyncThunk(
    "login/fetchLogin",
    async (input) => {

        try {
            const { email, pssw } = input;

            const response = await fetch(`${process.env.REACT_APP_URL_SERVER_LOCAL}/userlogin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: email.replace(/\s+/g, ' ').trim(),
                    pssw: pssw.replace(/\s+/g, ' ').trim()
                })
            });

            return await response.json();

        } catch (error) {
            console.error(error);
        }
    }
)

const loginSlice = createSlice({
    name: "loginSlice",
    initialState,
    reducers: {
        setIsLogged: (state, action) => {
            state.isLogged = action.payload
        },
        setSuperUser: (state, action) => {
            state.isSuperUser = action.payload
        }
    },
    extraReducers: (builder) => {
        //login
        builder.addCase(loginFunc.pending, (state, action)=>{
            state.isLoading = true
        });
        builder.addCase(loginFunc.fulfilled, (state, action)=>{
            state.isLoading = false
        });
        builder.addCase(loginFunc.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = "server error extrareducers"
        });
    }
})



export const { setIsLogged, setSuperUser } = loginSlice.actions;
export default loginSlice.reducer;
export { loginFunc }