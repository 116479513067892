import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import animationBg from '../../assets/images/3Danimations/renderBg.jpg';
import graphic from '../../assets/images/3Danimations/graphic-tablet.png';
import complex from '../../assets/images/3Danimations/problem-solving.png';

const AnimationsPage = () => {
  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${animationBg})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>3D & 2D Animation</h1>
            <h2 className='font-nycd text-info my-5'>GO BEYOND WORDS</h2>
            <h3 className='fw-light'>Enhance your marketing efforts by effectively communicating the uniqueness of your products and leave a lasting impression on your ideal customers. </h3>
          </div>
        </div>

        <div className='bg-dark'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex flex-wrap justify-content-center gap-1'>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Vj5RT8Yx7-s?si=TltJZt6HZNcsO6IS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/q2flxu0ofac?si=MAt-fjoPq-0KsTKZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/_LXoHC-Nn2s?si=cO1spImGje4lUFXL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/4p3BOsq-RpY?si=7C7mfgfjwmFkcg1j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/kuoAOVT7j_Q?si=YUWDrf4eeBqc3E0e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className='ratio ratio-16x9 myMaxWidth1000'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/n_SwwVa3GjE?si=q3qMQoYscmcmahaz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 d-flex flex-column align-items-center bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='py-5 pt-5 d-flex justify-content-center align-items-center gap-4'>
            <img className='myMaxWidth200' src={complex} alt="img" />
          </div>
          <h2 className=' text-center myMaxWidth1200 mb-4'>Technology is complex and products are unique. Sometimes, words are not enough: that’s when animations step in. With special effects and creative designs, 3D animations offer a <b>dynamic and visually compelling way to convey multifaceted information</b>, making it easier for audiences to understand the product and engage with it.</h2>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 mb-5 text-secondary'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-column gap-5'>
            <h1 className='font-nycd mt-5 mb-5 text-center fw-light text-info myMaxWidth1600'>Enhance your marketing efforts by effectively communicating the uniqueness of your products and leave a lasting impression on your ideal customers.  </h1>
            <img className='myMaxWidth200' src={graphic} alt="" />
            <div>
              <h1 className='myMaxWidth1200 text-center text-dark display-3'>We offer two different types of animations:</h1>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='text-center myMaxWidth1400 pt-4'>
              <h1 className='mt-5'> <span className='montserrat-alternates-light ps-3 myTextFucsia' style={{ borderLeft: "4px solid #e82283" }}><b>Commercial Animations </b></span> </h1>
              <h2 className='mt-4 pb-5'> these animations – longer and more elaborated – are ideal for explaining dental products of medium/high complexity, focusing on their features and characteristics. They are designed to be uploaded on video-sharing platforms such as YouTube and Vimeo, as well as to be spread through newsletters.</h2>
              <h1 className='mt-5'> <span className='montserrat-alternates-light ps-3 myTextAcqua' style={{ borderLeft: "4px solid #14a7ad" }}><b>Social Animations </b></span> </h1>
              <h2 className='mt-4 mb-3'> we help businesses who are looking to release publications about their products, reports, monographs, or other kinds of works, by providing editorial assistance, including authors who can take care of writing.</h2>
            </div>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

      </div>
    </MainLayout>
  )
}

export default AnimationsPage