import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import event1 from '../../assets/images/coursesWebinars/events_1.jpg';
import course from '../../assets/images/coursesWebinars/course.jpg';
import team from '../../assets/images/coursesWebinars/team.png';
import onlineStudy from '../../assets/images/coursesWebinars/onlineCourse.jpg';
import format4K from '../../assets/images/videoInterview/4k-film.png';
import volume from '../../assets/images/videoInterview/volume.png';

const CoursesWebinarPage = () => {
  return (
    <MainLayout>
      <div className='bg-light' style={{ maxHeight: "calc(100vh - 59px)", overflowY: "scroll" }}>

        <div className=' text-center myBgImgCover' style={{ backgroundImage: `url(${course})` }}>
          <div className='px-4 py-5 myBgTransparent60 text-light'>
            <h1 className='montserrat-alternates-bold'>Courses & Webinars</h1>
            <h2 className='font-nycd text-info my-5'>PASS ON THE KNOWLEDGE</h2>
            <h3 className='fw-light'>We can help you organize courses and webinars aimed at dental and medical professionals. </h3>
          </div>
        </div>

        <div className='py-4 d-flex flex-column align-items-center'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='m-4 d-flex justify-content-center align-items-center flex-wrap gap-5'>
            <h2 className='text-secondary myMaxWidth700'>Courses & Webinars offer an incredible opportunity to share the knowledge as they provide a <b>structured, organized, and interactive experience to participants</b>. And with our assistance, they will no longer be hard to plan and carry out.</h2>
            <img className='w-100 myMaxWidth700' src={event1} alt="" />
          </div>
          <h1 className='font-nycd my-5 text-center fw-light text-info'>Invest in Education!</h1>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-5 bg-dark text-light'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <h2 className='myMaxWidth700'>Thanks to our expertise and our dedicated team, we can help you organize courses and webinars aimed at dental and medical professionals, as well as in-house staff training within your company. We offer <b>technical assistance</b>, but also <b>logistical and organizational support</b> to plan and hold the workshop you have in mind, <b>both with and without ECM credits.</b> </h2>
            <img className='myMaxWidth200' src={team} alt="" />
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='p-4'>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
          <div className='d-flex py-3 align-items-center flex-wrap justify-content-around gap-5'>
            <img className='w-100 myMaxWidth700' src={onlineStudy} alt="" />
            <h2 className='text-secondary myMaxWidth700'> According to your needs and those of your audience, the course can be held in <b>presence or remotely</b>, taking advantage of new technologies and specialized online platforms. </h2>
          </div>
          <div className='spacerLg'>{/* adaptive spacer */}</div>
        </div>

        <div className='text-center myBgImgCover' style={{ backgroundImage: `url(${course})` }}>
          <div className='p-5 myBgTransparent60 text-light d-flex flex-column align-items-center'>
            <div className='spacerLg'>{/* adaptive spacer */}</div>
            <div className='py-5 pt-5 d-flex justify-content-center gap-4'>
              <img className='myMaxWidth80' src={format4K} alt="img" />
              <img className='myMaxWidth80' src={volume} alt="img" />
            </div>
            <h2 className='fw-light my-5 myMaxWidth1200'>We also offer the necessary assistance to hold courses and trainings in collaboration with <b>professional associations</b>.
              Upon request <b>all recordings can be made available after the event</b>, so you can share it with the participants to enhance their experience.
            </h2>
            <i className='mb-5 bi bi-info-circle'> This service is only available in Italy. Price upon request and availability.</i>
          </div>
        </div>

      </div>
    </MainLayout>
  )
}

export default CoursesWebinarPage