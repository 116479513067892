import { React, useEffect, useState } from 'react';
import ServiceShortCard from './ServiceShortCard';
import ServiceFocusCard from './ServiceFocusCard';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { setServiceCategory } from '../states/navigationState';
import digitalLogo from '../assets/logo/digitalLogoSM.png';
import paperLogo from '../assets/logo/paperLogoSM.png';
import eventsLogo from '../assets/logo/eventsLogoSM.png';

const _OurServices = () => {
    const category = useSelector((state) => state.navigation.serviceCategory);
    const dispatch = useDispatch();

    return (

        <div>
            <div className='my-2 text-center'>
                <DropdownButton
                    as={ButtonGroup}
                    key={0}
                    id="dropdown-button-drop-0"
                    size="sm"
                    variant="light"
                    title={category}
                >
                    <Dropdown.Item key="1" onClick={() => dispatch(setServiceCategory("All Services"))}>
                        All Services
                        <img className='ms-2' src={digitalLogo} alt="" style={{ height: "20px" }} />
                        <img className='ms-1' src={eventsLogo} alt="" style={{ height: "20px" }} />
                        <img className='ms-1' src={paperLogo} alt="" style={{ height: "20px" }} />
                    </Dropdown.Item>
                    <Dropdown.Item key="2" onClick={() => dispatch(setServiceCategory("Digital"))}>
                        Digital
                        <img className='ms-2' src={digitalLogo} alt="" style={{ height: "20px" }} />
                    </Dropdown.Item>
                    <Dropdown.Item key="3" onClick={() => dispatch(setServiceCategory("Paper"))}>
                        Paper
                        <img className='ms-2' src={paperLogo} alt="" style={{ height: "20px" }} />
                    </Dropdown.Item>
                    <Dropdown.Item key="4" onClick={() => dispatch(setServiceCategory("Events"))}>
                        Events
                        <img className='ms-2' src={eventsLogo} alt="" style={{ height: "20px" }} />
                    </Dropdown.Item>
                </DropdownButton>
            </div>

            <ServiceShortCard />

        </div>

    )
}

export default _OurServices