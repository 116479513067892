import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setMember, resetMembers, setReloadFetch, setEditingFair, setFairInDepth, setAddingNewFair, updateNewFairFunc } from '../states/reportState';
import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { DateRange } from 'react-date-range';


const ReportsFairFormEdit = ({ user, team }) => {
    const fairData = useSelector((state) => state.report.fairData);
    const partecipants_ = useSelector((state) => state.report.partecipants);
    const isUpdatingFair = useSelector((state) => state.report.isUpdatingFair);
    const dispatch = useDispatch();

    const [eventName, setEventName] = useState(fairData.eventName);
    const [year, setYear] = useState(fairData.year);
    const [stand, setStand] = useState(fairData.stand);
    const [positionRate, setPositionRate] = useState(fairData.positionRate);
    const [prevEditionRate, setPrevEditionRate] = useState(fairData.prevEditionRate);
    const [materialDistribution, setMaterialDistribution] = useState(fairData.materialDistribution);
    const [problems, setProblems] = useState(fairData.problems);
    const [comments, setComments] = useState(fairData.comments);
    const [generalRate, setGeneralRate] = useState(fairData.generalRate);
    const [notes, setNotes] = useState(fairData.notes);
    const [partecipants, setPartecipants] = useState(fairData.partecipants);
    const [visitsCompleted, setVisitsCompleted] = useState(fairData.visitsCompleted);
    const [databaseCompleted, setDatabaseCompleted] = useState(fairData.databaseCompleted);

    const [message, setMessage] = useState("");
    const [isEdited, setIsEdited] = useState(false);

    //star rating
    const [isSelected, setIsSelected] = useState(false);


    const editFair = async () => {
        try {
            const payload = {
                id: fairData.id,
                eventName: eventName,
                year: year,
                date: `${state[0].startDate}%${state[0].endDate}`,
                stand: stand,
                positionRate: positionRate,
                prevEditionRate: prevEditionRate,
                materialDistribution: materialDistribution,
                problems: problems,
                comments: comments,
                generalRate: generalRate,
                notes: notes,
                partecipants: partecipants,
                visitsCompleted: visitsCompleted,
                databaseCompleted: databaseCompleted,
                updatedBy: user
            };

            dispatch(updateNewFairFunc(payload));

            setMessage("");
            setIsEdited(true);//to avoid first open reload and reset of the form
            return

        } catch (error) {
            console.error(error);
        }

    };

    const [state, setState] = useState([
        {
            startDate: new Date(fairData.date.split("%")[0]),
            endDate: new Date(fairData.date.split("%")[1]),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        if (!isUpdatingFair&&isEdited) {
            dispatch(setEditingFair(false));
            dispatch(setFairInDepth(false));
            dispatch(setAddingNewFair(false));
            dispatch(setReloadFetch());
            setIsEdited(false);
        }
    }, [isUpdatingFair])

    useEffect(() => {
        if (partecipants_.length === 0) { setPartecipants(fairData.partecipants.toString()) } else { setPartecipants(partecipants_.toString()) }
    }, [partecipants_])

    return (
        <div className='pt-2 px-2 pb-2 pt-4' >

            <h2 className='text-primary fw-light'>Edit the event</h2>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Event Name
                </InputGroup.Text>
                <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setEventName(e.target.value) }}
                    value={eventName}
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Year
                </InputGroup.Text>
                <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setYear(e.target.value) }}
                    value={year}
                />
            </InputGroup>
            
            <DateRange className="mb-4"
                editableDateInputs={true}
                onChange={(item) => {setState([item.selection])}}
                moveRangeOnFirstSelection={false}
                ranges={state}
            />

            <div className="mb-3">
                <h6 className='text-light m-0 mb-1'><i className="bi bi-shop"> Stand</i></h6>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Stand"
                    onClick={(e) => { setStand(e.target.checked ? 1 : 0) }}
                    defaultChecked={stand ? "cheked" : ""}
                />
            </div>

            <div className='mb-3'>
                <h6 className='text-light m-0'><i className="bi bi-award-fill"> General Rate</i></h6>
                <div>
                    {
                        [...Array(5)].map((el, index) => {
                            return <i

                                className={`bi bi-star${generalRate > index ? "-fill" : ""} postRatingStars`}
                                onMouseEnter={() => { if (!isSelected) { setGeneralRate(index + 1) } }}
                                onMouseLeave={() => { if (!isSelected) { !isSelected ? setGeneralRate(0) : setGeneralRate(index + 1) } }}
                                onClick={() => { setGeneralRate(index + 1); setIsSelected(true) }}></i>

                        })
                    }
                </div>
            </div>

            <div className='mb-3'>
                <h6 className='text-light m-0'><i className="bi bi-geo-alt-fill"> Position Rate</i></h6>
                <div>
                    {
                        [...Array(5)].map((el, index) => {
                            return <i

                                className={`bi bi-star${positionRate > index ? "-fill" : ""} postRatingStars`}
                                onMouseEnter={() => { if (!isSelected) { setPositionRate(index + 1) } }}
                                onMouseLeave={() => { if (!isSelected) { !isSelected ? setPositionRate(0) : setPositionRate(index + 1) } }}
                                onClick={() => { setPositionRate(index + 1); setIsSelected(true) }}></i>

                        })
                    }
                </div>
            </div>

            <div className='mb-3'>
                <h6 className='text-light m-0'><i className="bi bi-graph-up-arrow"> Prev. Edition Rate</i></h6>
                <div>
                    {
                        [...Array(5)].map((el, index) => {
                            return <i

                                className={`bi bi-star${prevEditionRate > index ? "-fill" : ""} postRatingStars`}
                                onMouseEnter={() => { if (!isSelected) { setPrevEditionRate(index + 1) } }}
                                onMouseLeave={() => { if (!isSelected) { !isSelected ? setPrevEditionRate(0) : setPrevEditionRate(index + 1) } }}
                                onClick={() => { setPrevEditionRate(index + 1); setIsSelected(true) }}></i>

                        })
                    }
                </div>
            </div>

            <div>
                <h6 className='text-light m-0 mb-2'><i className="bi bi-box-seam-fill"> Material Distribution</i></h6>
                <InputGroup className="mb-3">
                    <Form.Control
                        as="textarea"
                        rows="3"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={(e) => { setMaterialDistribution(e.target.value) }}
                        value={materialDistribution}
                    />
                </InputGroup>
            </div>
            <div>
                <h6 className='text-light m-0 mb-2'><i className="bi bi-emoji-angry-fill"> Problems</i></h6>
                <InputGroup className="mb-3">
                    <Form.Control
                        as="textarea"
                        rows="4"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={(e) => { setProblems(e.target.value) }}
                        value={problems}
                    />
                </InputGroup>
            </div>
            <div>
                <h6 className='text-light m-0 mb-2'><i className="bi bi-chat-dots-fill"> Comments</i></h6>
                <InputGroup className="mb-3">
                    <Form.Control
                        as="textarea"
                        rows="3"
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={(e) => { setComments(e.target.value) }}
                        value={comments}
                    />
                </InputGroup>
            </div>


            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    Notes
                </InputGroup.Text>
                <Form.Control
                    as="textarea"
                    rows="9"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    onChange={(e) => { setNotes(e.target.value) }}
                    value={notes}
                />
            </InputGroup>

            <h6 className='text-light'><i className="bi bi-people-fill"> partecipants</i></h6>
            <div className='text-light mb-2 d-flex flex-wrap'>
                {partecipants && partecipants.split(",").map((el) => { return <div className='bg-info m-1 p-1 px-3 rounded-5'>{" " + team[+el - 1].name + " " + team[+el - 1].surname}</div> })}
            </div>
            <div className='d-flex align-items-center justify-content-between mb-5'>
                <Dropdown className='mt-2'>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Choose a partecipants
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {team && team.map((el) => {
                            return <Dropdown.Item onClick={() => { dispatch(setMember(el.id)) }}>{el.name + " " + el.surname}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <i className="bi bi-trash-fill text-danger" onClick={() => { dispatch(resetMembers()); setPartecipants("") }} style={{ cursor: "pointer" }}> clear</i>
            </div>

            <div className="mb-3">
                <h6 className='text-light m-0 mb-1'><i className="bi bi-check2-circle"> Visits completed</i></h6>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Visits"
                    onClick={(e) => { setVisitsCompleted(e.target.checked ? 1 : 0) }}
                    defaultChecked={visitsCompleted ? "cheked" : ""}
                />
            </div>

            <div>
                <h6 className='text-light m-0 mb-1'><i className="bi bi bi-database-fill-up"> Database filled</i></h6>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Database"
                    onClick={(e) => { setDatabaseCompleted(e.target.checked ? 1 : 0) }}
                    defaultChecked={databaseCompleted ? "cheked" : ""}
                />
            </div>


            <Button className='mt-5 w-100 text-light' variant="primary" size="sm" onClick={() => { editFair() }}>
                {
                    isUpdatingFair ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> :
                        <i className='bi bi-arrow-repeat '></i>
                } Update
            </Button>
            <p className='text-light mt-2'>{message}</p>
        </div>
    )
}

export default ReportsFairFormEdit